<template>
  <div class="py-3">
    <div class="d-flex flex-row-reverse mr-4 modifyBtn">
      <v-btn
        v-show="!editMode"
        id="modify"
        :disabled="allowUserToEditOrNot || editMode"
        @click="$emit('modifyDose')"
        
      >
        Modify
      </v-btn>
    </div>
    <v-row class="px-3 d-flex flex-row-reverse">
      <v-col cols="12" lg="6" sm="6">
        <v-card>
          <div v-show="editMode" class="py-4 mb-n2 mt-13">
            <v-container mt-n6>
              <v-form v-model="formValid">
                <v-layout row pa-4>
                  <v-flex
                    v-for="(field, index) in allFields"
                    :key="index"
                    xs12
                    sm4
                    lg4
                    xl4
                    pr-4
                  >
                    <component
                      :is="field.type"
                      v-if="field.type === 'radio'"
                      v-model="doseDetails[field.key]"
                      :append-text="field.unit"
                      :label="field.label"
                      :disabled="field.isReadonly"
                      :field-key="field.key"
                      :items="field.items"
                    />
                    <component
                      :is="field.type"
                      v-else
                      v-model="doseDetails[field.key]"
                      :items="field.items"
                      :allow-user-to-edit-or-not="allowUserToEditOrNot"
                      :disabled="field.isReadonly || allowUserToEditOrNot"
                      :validate="validate"
                      :append-text="field.unit"
                      :label="field.label"
                      :field-key="field.key"
                      :required="field.isRequired"
                      :message="field.message"
                      :error-message="field.errorMessage"
                      :common-function="commonFunction"
                      @change="onChange($event, field)"
                    />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <!-- <hr /> -->
            <div class="d-flex justify-end mt-n8 mb-n2 mr-1">
              <v-btn
                :loading="saving"
                @click="onRecalculate"
                id="update"
              >
                Update
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DosageInfo from "@/components/patient/DosageInfo";
import NumberInput from "@/components/form/NumberInput";
import Dropdown from "@/components/form/Dropdown";
import Number from "@/components/form/NumberInput";
import Radio from "@/components/form/Radio";
export default {
  name: "ModifyDosage",
  components: { Dropdown, DosageInfo, NumberInput, Number, Radio },
  props: {
    schema: { type: Array, required: true, default: () => [] },
    generalDetails: { type: Object, required: true, default: () => ({}) },
    doseDetails: { type: Object, required: true, default: () => ({}) },
    suggestedDoseDetails: { type: Object, required: true, default: () => ({}) },
    enableNavigation: { type: Boolean, required: true, default: true },
    saving: { type: Boolean, required: true, default: true },
    allowUserToEditOrNot: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      validate: false,
      allFields: [],
      formValid: false,
    };
  },
  computed: {
    editMode() {
      return !this.enableNavigation;
    },
    dailyDose() {
      return this.dosage(this.doseDetails);
    },
  },
  mounted() {
    this.allFields = this.schema;
  },
  methods: {
    onRecalculate() {
      this.validate = true;
      this.$emit("recalculate", this.formValid);
    },
    async action(v, key) {
      const valid = v.fields.every(
        (field) =>
          this.doseDetails[field] != undefined && this.doseDetails[field] != ""
      );
      if (valid) {
        this.commonFunction(v, key, "doseRecommendation");
      }
    },
    async onChange(value, field) {
      if (value) {
        if (field.validator != undefined) {
          field.validator.map((valid) => {
            if (valid.type == "dependent") {
              valid.fields.map((field) => {
                this.allFields.map((f) => {
                  if (f.key == field) {
                    f.validator.map((v) => {
                      if (v.type == "internal" || v.type == "external") {
                        this.commonFunction(v, field, "doseRecommendation");
                      }
                    });
                  } else {
                    if (f.validator != undefined) {
                      f.validator.map((v) => {
                        if (v.type == "internal" || v.type == "external") {
                          setTimeout(() => {
                            this.commonFunction(v, f.key, "doseRecommendation");
                          }, 500);
                        }
                      });
                    }
                  }
                });
              });
            } else {
              if (valid.type == "external") {
                this.commonFunction(valid, field, "doseRecommendation");
              }
            }
          });
        }
      }
    },
    commonFunction(v, key, type) {
      this.$emit("commonFunction", v, key, type);
    },
  },
};
</script>
<style scoped>
hr {
  opacity: 0.1;
}
.modifyBtn {
  margin-top: -1.8em;
}
.title {
  background-color: #0035c7;
  height: 2em;
  color: white;
}
.v-application .py-3 {
  /* padding-top: 12px!important; */
  padding-bottom: 0px !important;
}
#update {
  background-color: #0035c7;
  color:white;
}
#modify {
  background-color: #0035c7;
  color:white;
}
</style>
