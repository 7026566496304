<template>
  <v-form v-model="validEvent">
    <v-layout wrap>
      <v-flex
        px-2
        x12
        sm4
      >
        <component
          :is="fields.type"
          id="Dose Level"
          v-model="event.type"
          :label="fields.label"
          :append-text="fields.unit"
          :items="fields.items"
          @input="onChange($event, fields)"
        />
      </v-flex>
      <v-flex
        v-for="field in subFields"
        :key="field.key"
        px-2
        x12
        sm4
      >
        <Datepicker
          :is="field.type"
          v-if="field.key == 'date'"
          v-model="event.date"
          :label="field.label"
          :max-date="today"
          :required="field.isRequired"
        />
        <Timepicker
          v-else-if="field.key == 'time'"
          v-model="event.time"
        />
        <component
          :is="field.type"
          v-else
          v-model="event.values[field.key]"
          :label="field.label"
          :append-text="field.unit"
          :items="field.items"
          :validate="validate"
          :required="field.isRequired"
        />
      </v-flex>
      <v-flex
        x12
        xs12
      >
        <v-btn
          class="mr-3"
          color="success"
          @click="onSave"
        >
          Save
        </v-btn>
        <v-btn
          color="error"
          class
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import Date from '@/components/form/Datepicker';
import Timepicker from '@/components/form/Timepicker';
import Dropdown from '@/components/form/Dropdown';
import Number from '@/components/form/NumberInput';
import { DateTime } from 'luxon';
import { get } from 'lodash';
// import schema from '../../assets/events';
import config from "../../config/instance";
import _ ,{orderBy} from "lodash";

export default {
  name: 'EventEntry',
  components: {
    Number,
    Dropdown,
    Date,
    Timepicker,
  },
  props: {
    editEvent: { type: Object, default: () => ({}) },
    generalDetails: { type: Object,  default: () => ({}) },
    schema: { type: Array, required: true,  default: () => [] },
  },
  data() {
    return {
      validEvent: false,
      validate: false,
      today: DateTime.local().toISODate(),
      patientDetails: {},
      event: null,
      fields: [],
      subFields: [],
      defaultType: 'Dose',
    };
  },
  computed: {
    height() {
      return get(this.generalDetails, 'height', '');
    },
    weight() {
      return get(this.generalDetails, 'weight', '');
    },
    scr() {
      return get(this.generalDetails, 'scr', '');
    },
  },
  watch: {
    editEvent: {
      immediate: true,
      handler(value) {
        this.event = value || this.defaultItem();
        this.inputChange();
      },
    },
  },
  mounted() {
    this.fields = this.schema[0];
    this.inputChange();
  },
  methods: {
    inputChange() {
      this.subFields = orderBy(_.find(this.schema[0].items, { value: this.event.type}).field, ["order"], ["asc"]);
    },
    onSave() {
      this.validate = true;
      if (this.validEvent) {
        this.$emit('save', this.event);
      }
    },
    defaultItem() {
      let dateTime = DateTime.fromObject({
        zone: config.date_time.events.zone
      });
      return {
        values: {
          height: this.height,
          weight: this.weight,
          egfr: '',
          ffm: '',
          scr: this.scr,
          dv: '',
        },
        event: '',
        date: dateTime.toISODate(),
        time: dateTime.toLocaleString(DateTime.TIME_24_SIMPLE),
        type: this.defaultType,
        isInsert: true,
        timestamp: '',
      };
    },
    onChange(value, fields) {
      this.defaultType = value;
      if (value == 'Dose') {
        this.subFields =orderBy(fields.items[0].field ,["order"], ["asc"]);
      } else {
        this.subFields = orderBy(fields.items[1].field ,["order"], ["asc"]);
      }
    },
  },
};
</script>
