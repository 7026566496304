<template>
  <v-container>
    <div
      v-for="msg in content"
      :key="msg.key"
    >
      <v-alert
        v-if="msg.content || studyId"
        text
        close-text="Close Alert"
        dark
        dismissible
        :class="msg.class"
      >
        <b>
          {{ msg.content }}
        </b>
      </v-alert>
    </div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-actions>
              <v-card-title class="v-card__title-dashboard">
                {{ conditionName }}
              </v-card-title>
              <v-spacer />

              <v-btn
                id="add patient"
                name="add patient"
                :to="`/${condition}/treatments/${treatment}/newPatient`"
                color="#EF515B"
                outlined
                right
                text
              >
                Add Patient
              </v-btn>
            </v-card-actions>
            <v-tabs
              v-model="activeTab"
              color="#EF515B"
            >
              <v-tab
                v-for="treatments in treatmentData"
                :key="treatments.creMent"
                dark
                left
                @click="routeTo(treatments)"
              >
                {{ treatments.route }}
              </v-tab>
              <v-tab-item
                v-for="treatments in treatmentData"
                :key="'key' + treatments.id"
              >
                <v-card text>
                  <v-card-title>
                    <v-text-field
                      id="search"
                      v-model="search"
                      name="search"
                      append-icon="mdi-magnify"
                      hide-details
                      label="Search"
                      single-line
                    />
                  </v-card-title>

                  <v-data-table
                    :headers="headers"
                    :items="patients"
                    :search="search"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                    @click:row="handleClick"
                  >
                    <template #[`item.DOB`]="{ item }">
                      {{ formatDate(item.DOB) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
  </v-container>
</template>

<script>
// import moment from "moment";
import { Main } from "../../services/modules";
import config from "../../config/instance";
import constant from "../../config/constant";
import Error_Message from "@/utils/notification";
import {orderBy} from "lodash";

export default {
  name: "Dasboard",
  data() {
    return {
      content:"",
      studyId: false,
      loading: false,
      selection: 1,
      search: "",
      activeTab: 0,
      conditionName: "",
      ui: {
        retrieveLoading: false,
        infoSnackbar: {
          state: false,
          color: "error",
          info: null,
          timeout: 6000,
        },
      },
      patients: [],
      treatment: null,
      treatmentData: [],
      condition: null,
      error: false,
      showResult: false,
      result: "",
      headers: [],
    };
  },
  mounted() {
    const { condition, treatment } = this.$route.params;
    this.condition = condition;
    this.treatment = treatment;
    this.getCondition(condition);
    this.getAllTreatment(condition);
    // this.getStudyID();
    if(config.notification){
       this.content=orderBy(config.notification.patient ,["order"], ["asc"]);
    }
  },
  methods: {
    setTabActive(treatment) {
      this.activeTab = treatment;
    },
    routeTo(route) {
      this.treatment = route.id;
      this.$router.push({
        path: `/${this.condition}/treatments/${route.id}/patientList`,
      });
      this.getAllPatient(route.id);
    },
    getAllPatient(params) {
      console.log(params,"params");
      this.loading = true;
      Main.getAllPatient(params)
        .then((response) => {
          this.patients = [];
          this.headers = [
            {
              text: "MRN",
              value: "MRN",
            },
          ];
          let patientsData = response.data.data;
          for (let index = 0; index < patientsData.length; index++) {
            const e = patientsData[index];

            this.patients.push({
              MRN: e.mrn,
            });
          }
          this.loading = false;
        })
        .catch((e) => {
           this.$store.commit(
          "snackbar/setSnack",
           Error_Message.display_message(e.data.message[0].message.concat(" ", e.data.message[1].message) || e.data.message)
        );
        });
    },
    getCondition(params) {
      Main.getCondition(params)
        .then((response) => {
          this.conditionName = response.data.data.name + " module";
        })
        .catch((e) => {
           this.$store.commit(
          "snackbar/setSnack",
           Error_Message.display_message(e.data.message[0].message.concat(" ", e.data.message[1].message) || e.data.message )
        );
        });
    },
    getAllTreatment(params) {
      let vm = this;
      Main.getAllTreatment(params)
        .then((response) => {
          this.treatmentData = [];
          let treatmentList = response.data.data;
          let i = 0;
          for (let treatment in treatmentList) {
            this.treatmentData.push({
              id: treatmentList[treatment]._id,
              route: treatmentList[treatment].name,
              creMent: i++,
            });
          }
          if (this.treatmentData.length > 0) {
            if (this.treatment == undefined) {
              this.routeTo(this.treatmentData[0]);
            } else {
              this.getAllPatient(this.treatment);
              let index = this.treatmentData.findIndex(
                (x) => x.id === this.treatment
              );
              this.setTabActive(index);
            }
            return;
          }
          vm.error = true;
          vm.result = constant.patient.content;
          vm.showResult = true;
        })
        .catch((e) => {
          this.$store.commit(
          "snackbar/setSnack",
           Error_Message.display_message(e.data.message[0].message.concat(" ", e.data.message[1].message) || e.data.message)
        );
          // if(error){
          //    vm.error = true;
          //    vm.result = "Something went wrong!";
          //    vm.showResult = true;
          // }
        });
    },
    handleClick(value) {
      let path = `/${this.condition}/treatments/${this.treatment}/patientStatus/${value.MRN}`;
      this.$router.push({ path });
    },
  },
};
</script>
<style>
.v-tab--active {
  background-color: #ef515b !important;
  color: white !important;
}
.v-tabs-bar {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
tr {
  cursor: pointer !important;
}
.blue--text {
  color: #085391 !important;
}
.v-card__title-dashboard {
  color: #6da2d4 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  text-transform: capitalize;
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* .v-tab{
  width: 200px !important;
} */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* .v-tab{
  width: 200px !important;
} */
}
.alert__content {
  color: black;
}
</style>
