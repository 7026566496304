<template>
  <div>
    <Card
      title="Patient"
      :loading="loading"
    >
      <PatientBasic
        ref="patientBasic"
        :new-patient="newPatient"
        :existing-patient-details.sync="generalDetails"
        :allow-user-to-edit-or-not="allowUserToEditOrNot"
        :schema="_.find(schema, { title: 'Patient' }).fields"
      />
    </Card>
    <Card
      title="Patient Information"
      :loading="loading"
    >
      <PatientInformation
        v-if="schema.length > 0"
        ref="patientInformation"
        :existing-dose-details.sync="doseDetails"
        :existing-patient-details.sync="generalDetails"
        :patient-model="patientModel"
        :allow-user-to-edit-or-not="allowUserToEditOrNot"
        :schema="_.find(schema, { title: 'Patient information' }).fields"
        @validated="onValidated"
        @change="onChange($event, field)"
        @commonFunction="commonFunction"
      />
    </Card>
    <Card
      title="Events"
      :loading="loading"
    >
      <EventTable
        :general-details="generalDetails"
        :existing-events.sync="events"
        :patient-model="patientModel"
        :allow-user-to-edit-or-not="allowUserToEditOrNot"
        :schema="_.find(schema, { title: 'Events' }).fields"
      />
    </Card>
  </div>
</template>

<script>
import Card from "@/components/ui/Card";
import PatientBasic from "@/components/patient/PatientBasic";
import PatientInformation from "@/components/patient/PatientInformation";
import EventTable from "@/components/patient/EventTable";
import { orderBy } from "lodash";
import _ from "lodash";

export default {
  name: "PatientInformationTab",

  components: {
    Card,
    PatientBasic,
    PatientInformation,
    EventTable,
  },

  props: {
    newPatient: { type: Boolean, required: true, default: true },
    loading: { type: Boolean, required: true, default: false },
    patientModel: { type: String, default: "" },
    generalDetails: { type: Object, required: true, default: () => ({}) },
    doseDetails: { type: Object, required: true, default: () => ({}) },
    events: { type: Array, required: true, default: () => [] },
    allowUserToEditOrNot: { type: Boolean, required: false, default: false },
    patientDetails: { type: Object, required: true, default: () => ({}) },
    schema: { type: Array, required: true, default: () => [] },
  },
  computed: {
    _() {
      return _;
    },
  },
  watch: {
    schema(value) {
      this.fields = orderBy(value, ["order"], ["asc"]);
    },
  },
  methods: {
    triggerValidate() {
      this.$refs.patientInformation.validate = false;
      this.$refs.patientBasic.validate = false;
      this.$nextTick(() => {
        this.$refs.patientInformation.validate = true;
        this.$refs.patientBasic.validate = true;
      });
    },
    onValidated() {
      const isValid =
        this.$refs.patientInformation.formValid &&
        this.$refs.patientBasic.formValid;
      this.$emit("valid", isValid);
    },
    commonFunction(v, key, type) {
      this.$emit("commonFunction", v, key, type);
    },
  },
};
</script>
