<template>
  <v-layout row py-3>
    <v-flex
      xs12
      lg6
      md12
      px-4
    >
      <Card
        title="Graph"
        :loading="loading"
      >
        <TimelineGraph
          visible
          :graph-data="graphData"
        />
      </Card>
    </v-flex>
    <v-flex
      xs12
      lg6
      md12
      px-4
    >
      <Card
        title="Summary"
        :loading="loading"
      >
        <EventTable
          :existing-events="events"
          :mutable="false"
        />
      </Card>
    </v-flex>
  </v-layout>
</template>

<script>
import Card from "@/components/ui/Card";
import EventTable from "@/components/patient/EventTable";
import TimelineGraph from "@/components/ui/TimelineGraph";

export default {
  name: "SummaryTab",

  components: { TimelineGraph, EventTable, Card },

  props: {
    loading: { type: Boolean, required: true, default: false },
    events: { type: Array, required: true, default: () => [] },
    graphData: { type: Object, required: true, default: () => ({}) },
  },
};
</script>
