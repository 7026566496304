<template>
  <v-container class="mt-n5 mb-n10 pb-n4">
    <v-layout>
      <v-row>
        <v-col lg="6">
          <v-card>
            <v-card-title
              class="text-h6 d-flex justify-center white--text mb-2  pt-1 recommended"
            >
              Recommended
            </v-card-title>
            <v-card-text
              v-if="suggestedDoseDetails"
              class="text-subtitle-1 black--text mb-n3 pb-7"
              v-html="decodeEscapedHTML(suggestedDoseDetails.dosageRecommendation)"
            />
            <!-- <v-card-text>
          </v-card-text> -->
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title
              class="text-h6 d-flex justify-center white--text mb-2 current pt-1"
            >
              Current
            </v-card-title>
            <v-card-text
              v-if="doseDetails"
              class="text-subtitle-1 black--text pb-7"
              v-html="decodeEscapedHTML(doseDetails.dosageRecommendation)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
    <!-- <v-row> -->
    <!-- <v-btn
      v-if="isSuggested"
      small
      text
      color="primary"
      @click="$emit('applySuggestion')"
    >
      Apply
    </v-btn> -->
    <!-- </v-flex> -->
    <!-- </v-col> -->
    <!-- <v-col lg="6">
        <div class="n">
        <v-card>
          <v-card-text class="text-h5 d-flex justify-center current">
            Current
          </v-card-text>
          <v-card-text
            class="text-h6"
            v-if="doseDetails"
            v-html="doseDetails.dosageRecommendation"
          ></v-card-text>
        </v-card>
         </div>
      </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>
<script>
import {decode} from 'html-entities';
export default {
  name: "DosageInfo",
  props: {
    isSuggested: { type: Boolean, default: false },
    generalDetails: { type: Object, required: true, default: () => ({}) },
    doseDetails: { type: Object, required: true, default: () => ({}) },
    suggestedDoseDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {},
  methods: {
     decodeEscapedHTML(str) {
       return decode(str,{level: 'html5'})
  }
  },
};
</script>
<style scoped>
.recommended {
  background-color: #EF5151;
  height:2em;
}
.current {
  background-color: #0035C7;
  height:2em;
}
</style>