import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";
import layout from "../layout";
import PageNotFound from "@/pages/PageNotFound";
import Login from "@/pages/auth-pages/login";
import ChangePassword from "@/pages/auth-pages/changePassword";
import forgotPassword from "@/pages/auth-pages/forgotPassword";
import FileList from "@/pages/manage/file/FileList";
import AddFile from "@/pages/manage/file/AddFile";
import Treatment from "@/pages/manage/treatment/index";
import Manage from "@/pages/manage/index";
import Dashboard from "@/pages/dashboard/index";
import Profile from "@/pages/profile-page/Profile";
import Patient from "@/pages/patient/index";
import PatientDetail from "@/pages/patient/PatientDetail";
import Agreement from "@/pages/Agreement";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/conditions",
    component: layout,
  },
  {
    path: "/auth",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "change-password",
        name: "changePassword",
        meta: {
          requiresAuth: true,
        },
        component: ChangePassword,
      },
      {
        path: "forgot-password",
        name: "forgotPassword",
        component: forgotPassword,
      },
    ],
  },
  {
    path: "/file",
    component: layout,
    meta: {
      requiresAuth: true,
      allowedRoles: ["admin"],
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "FileList",
        component: FileList,
      },
      {
        path: "/new-file",
        name: "AddFile",
        component: AddFile,
      },
    ],
  },
  {
    path: "/schema",
    component: layout,
    meta: {
      requiresAuth: true,
      allowedRoles: ["admin"],
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Schema",
        component: Treatment,
      },
    ],
  },
  {
    path: "/manage",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Manage",
        component: Manage,
      },
    ],
  },
  {
    path: "/conditions",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Condition",
        component: Dashboard,
      },
      {
        path: "",
        name: "Profile",
        component: Profile,
      },
    ],
  },
  {
    path: "/:condition/treatments",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Treatment",
        component: Patient,
      },
    ],
  },
  {
    path: "/:condition/treatments/:treatment/patientList",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "PatientList",
        component: Patient,
      },
    ],
  },
  {
    path: "/:condition/treatments/:treatment/newPatient",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "NewPatient",
        component: PatientDetail,
      },
    ],
  },
  {
    path: "/:condition/treatments/:treatment/patientStatus/:mrn",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "EditPatient",
        component: PatientDetail,
      },
    ],
  },

  {
    path: "/user",
    component: layout,
    children: [
      // {
      //   path: "profile",
      //   name: "profile",
      //   meta: {
      //     requiresAuth: true,
      //     requiresAgreement: true,
      //   },
      //   component: import("@/pages/user/profile"),
      // },
      {
        path: "terms-and-condition",
        name: "acceptedTermsAndCondition",
        meta: {
          requiresAuth: true,
          requiresPasswordChange: true,
        },
        component: Agreement,
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "hash",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
});

export default router;
