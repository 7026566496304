<template>
  <div class="mt-6">
    <!-- <span v-for="(field, index) in fields" :key="index"> -->
    <!-- <div class="title text-capitalize">
      {{ drugname + " PK Parameters" }}
    </div> -->
    <v-layout
      row
      pa-2
    >
      <v-flex
        v-for="(field, index) in fields"
        :key="index"
        xs12
        sm4
        lg4
        xl3
        pr-4
      >
        <component
          :is="field.type"
          v-if="field.type === 'radio'"
          v-model="pkParameters[field.key]"
          :append-text="field.unit"
          :label="field.label"
          :disabled="field.isReadonly"
          :field-key="field.key"
          :items="field.items"
        />
        <component
          :is="field.type"
          v-else
          :value="roundedValue(field.key)"
          :append-text="field.unit"
          :label="field.label"
          :disabled="field.isReadonly"
        />
      </v-flex>
    </v-layout>
    <!-- </span> -->
  </div>
</template>

<script>
import { get } from "lodash";
import Number from "@/components/form/NumberInput";
import NumberInput from "@/components/form/NumberInput";
import Dropdown from "@/components/form/Dropdown";
import Radio from "@/components/form/Radio";

export default {
  name: "PKParameters",
  components: { Number, NumberInput, Dropdown, Radio },
  props: {
    schema: { type: Array, required: true, default: () => [] },
    pkParameters: { type: Object, required: true, default: () => ({}) },
    drugname: { type: String, required: true, default: "" },
  },
  data() {
    return {
      fields: [],
      rounded: [],
    };
  },
  mounted() {
    this.fields = this.schema;
  },
  methods: {
    roundedValue(key) {
      return get(this.pkParameters, key, 0);
    },
  },
};
</script>
