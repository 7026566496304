<template>
  <v-radio-group
    :id="id"
    row
    :value="value"
    :rules="applyRules"
    :error="isNotValid"
    @change="onChange($event)"
  >
    <template v-slot:label>
      <div>{{ label }}</div>
    </template>
    <v-radio
      v-for="item in items"
      :key="item.text"
      :label="item.text"
      :value="item.value"
    />
  </v-radio-group>
</template>

<script>
import validateInputMixin from "@/mixins/validateInputMixin";

export default {
  name: "Radio",

  mixins: [validateInputMixin],
  props: {
    items: { type: Array, required: true, default: () => [] },
    label: { type: String, default: "" },
  },
  data() {
    return {
      inputValue: false,
    };
  },
  mounted() {
    this.onChange(this.inputValue);
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style>
.v-label {
  text-transform: capitalize !important;
}
.v-label {
  line-height: 22px !important;
  color: rgba(0, 0, 0, 0.87);
}
</style>
